import {
	createSelector,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import {
	RootState,
	GenericState,
	DialogDetails,
	Category,
	Breadcrumb as BreadcrumbType,
} from '../types';
import { getDefaultUrl } from '../../bindings';
import {
	fetchCategories,
	getPromoMessages,
	fetchVehicles,
	fetchVehicle,
	subscribe,
	fetchSettings,
	getBlogPosts,
	newsletter_unsubscribe,
	fetchVehiclesForCategory,
	getSpecificBlogPost,
	searchBlog,
	fetchVehicleCategories,
	vehicleSearch,
	fetchFilteredVehicles,
} from '../thunks';
import { langPrompts } from '../../lang';
import {
	generateParentBreadcrumbs,
	getRecurringCategories,
} from '../../views';

const initialState: GenericState = {
	showCookieConsentDialog: false,
	unsubscribe: undefined,
	subscribe: undefined,
	baseUrl: getDefaultUrl(),
	isLoadingScreenShown: false,
	categories: {
		items: [],
		selectedItems: undefined,
		lastSelected: undefined,
		status: undefined,
		breadcrumbs: [
			{
				path: '/shop/products',
				name: 'Products',
				category_id: undefined,
				hasChildren: undefined,
			},
		],
	},
	cartPopover: { isOpen: false },
	accountPopover: { isOpen: false },
	localePopover: { isOpen: false },
	searchPopover: { isOpen: false },
	promoPopover: { isOpen: true },
	searchType: 'item',
	settings: {
		isBeingFetched: false,
		details: undefined,
	},
	vehicles: {
		isBeingFetched: false,
		displayable: undefined,
		details: {
			id: undefined,
			items: [],
		},
		selectedVehicle: undefined,
		selectedCategory: undefined,
		isQuoteRequestFormShown: false,
		vehicleForQuote: undefined,
		pagination: undefined,
		categories: [],
		status: undefined,
		parentCategories: undefined,
		filtersApplied: {
			years: [],
			active_discount: undefined,
		},
		sortApplied: {
			type: 'year',
			order: 'DESC',
			label: 'Newest',
		},
	},
	dynamicPages: {
		isBeingFetched: false,
		details: [],
	},
	contactForm: undefined,
	promoMessages: {
		items: undefined,
		status: undefined,
	},
	dialogDetails: {
		visible: false,
		content: undefined,
		isError: undefined,
		errorCode: undefined,
	},
	languagePrompts: langPrompts,
	blog: {
		posts: undefined,
		selectedPost: undefined,
		pagination: undefined,
		isBeingFetched: false,
		blogsBasedOnSearch: undefined,
	},
};

export const genericSlice = createSlice({
	name: 'generic',
	initialState,
	reducers: {
		setCookieConsentDialogVisibility: (
			state,
			action: PayloadAction<boolean>,
		) => {
			state.showCookieConsentDialog = action.payload;
		},
		updateSearchType: (
			state,
			{ payload }: { payload: 'item' | 'vehicle' },
		) => {
			state.searchType = payload;
		},
		toggleLoadingScreen: (
			state,
			{ payload }: { payload: boolean },
		) => {
			state.isLoadingScreenShown = payload;
		},
		updateDialogDetails: (
			state,
			{ payload }: { payload: DialogDetails },
		) => {
			state.dialogDetails = {
				...state.dialogDetails,
				...payload,
			};
		},
		updateBaseUrl: (
			state,
			action: PayloadAction<string>,
		) => {
			state.baseUrl = action.payload;
		},
		updateCartPopover: (
			state,
			{ payload }: { payload: boolean },
		) => {
			state.cartPopover.isOpen = payload;
		},
		updateSearchPopover: (
			state,
			{ payload }: { payload: boolean },
		) => {
			state.searchPopover.isOpen = payload;
		},
		updateAccountPopover: (
			state,
			{ payload }: { payload: boolean },
		) => {
			state.accountPopover.isOpen = payload;
		},
		updateLocalePopover: (
			state,
			{ payload }: { payload: { isOpen: boolean } },
		) => {
			state.localePopover.isOpen = payload.isOpen;
		},
		updatePromoPopover: (
			state,
			{ payload }: { payload: boolean },
		) => {
			state.promoPopover.isOpen = payload;
		},
		updateVehicleReuqestFormVisibility: (
			state,
			{ payload }: { payload: boolean },
		) => {
			state.vehicles = {
				...state.vehicles,
				isQuoteRequestFormShown: payload,
			};
		},
		updateVehicleForQuote: (state, { payload }) => {
			state.vehicles = {
				...state.vehicles,
				vehicleForQuote: payload,
			};
		},
		resetBreadcrumbs: (genericState) => {
			genericState.categories.lastSelected = undefined;
			genericState.categories.selectedItems = undefined;
			genericState.categories.breadcrumbs =
				initialState.categories.breadcrumbs;
		},
		updateBreadcrumbs: (
			genericState,
			{
				payload,
			}: {
				payload:
					| string
					| Array<
							Omit<BreadcrumbType, 'hasChildren'> & {
								hasChildren?: boolean;
							}
					  >;
			},
		) => {
			if (Array.isArray(payload)) {
				genericState.categories.breadcrumbs = [
					initialState.categories.breadcrumbs[0],
					...payload.map((item) => ({
						...item,
						hasChildren: item.hasChildren ?? undefined, // Ensure hasChildren is always defined
					})),
				];
				return;
			}

			const { breadcrumbs } = genericState.categories;
			const itemToResetTo = breadcrumbs.find(
				(item) => item.name === payload,
			);
			if (itemToResetTo) {
				const index = breadcrumbs.indexOf(itemToResetTo);
				if (index !== -1) {
					genericState.categories.breadcrumbs =
						breadcrumbs.slice(0, index + 1);
				}
				if (itemToResetTo.category_id === undefined) {
					genericState.categories.breadcrumbs =
						initialState.categories.breadcrumbs;
					genericState.categories.selectedItems = undefined;
				} else {
					const categoryToResetTo =
						genericState.categories.items.find(
							(category) =>
								category.id === itemToResetTo.category_id,
						);
					if (categoryToResetTo) {
						const selected = getRecurringCategories(
							categoryToResetTo,
						);
						genericState.categories.selectedItems =
							selected;
					}
				}
			}
		},
		resetForFilter: (genericState) => {
			const { breadcrumbs, selectedItems, lastSelected } =
				initialState.categories;
			genericState.categories.breadcrumbs = breadcrumbs;
			genericState.categories.selectedItems = selectedItems;
			genericState.categories.lastSelected = lastSelected;
		},
		applyVehicleSort: (state, { payload }) => {
			state.vehicles.sortApplied = payload;
		},
		updateSelectedCategoriesViaUrl: (
			genericState,
			{ payload }: { payload: string },
		) => {
			const categoryId = parseInt(payload);
			if (isNaN(categoryId)) {
				console.error('Invalid category id');
				return;
			}

			const categories =
				genericState.categories.items || [];
			const category = categories.find(
				(categ) => categ.id === categoryId,
			);

			if (!category) {
				console.warn('No category found', categoryId);
				return;
			}

			const selected = getRecurringCategories(category);

			if (!selected) {
				return;
			}

			if (selected.parent_id) {
				const parentCategory = categories.find(
					(categ) => categ.id === selected.parent_id,
				);

				if (parentCategory) {
					const parentBreadcrumbs =
						generateParentBreadcrumbs(
							categories,
							selected,
							genericState.categories.breadcrumbs || [],
						);
					genericState.categories = {
						...genericState.categories,
						breadcrumbs: [...parentBreadcrumbs],
					};
				} else {
					console.warn(
						'Parent category not found for ID:',
						selected.parent_id,
					);
				}
			}

			if (
				Array.isArray(selected.children) &&
				selected.children.length > 0
			) {
				const breadcrumbAlreadyExists =
					genericState.categories.breadcrumbs.some(
						(breadcrumb) =>
							breadcrumb.category_id === selected.id,
					);

				if (!breadcrumbAlreadyExists) {
					const newBreadcrumb = {
						name: selected.name,
						path: selected.path,
						category_id: selected.id,
						hasChildren: selected.children.length > 0,
					};

					genericState.categories = {
						...genericState.categories,
						breadcrumbs: [
							...genericState.categories.breadcrumbs,
							newBreadcrumb,
						],
					};
				}
			} else {
				console.warn(
					'No children found in selected category',
				);
			}

			genericState.categories = {
				...genericState.categories,
				selectedItems: selected,
				lastSelected: category,
			};
		},

		updateSelectedCategories: (
			genericState,
			{ payload }: { payload: Category },
		) => {
			if (!payload) {
				return;
			}

			const selected = getRecurringCategories(payload);
			if (!selected) {
				return;
			}

			if (!Array.isArray(selected.children)) {
				selected.children = [];
			}

			if (selected.children.length > 0) {
				const newBreadcrumb = {
					name: selected.name,
					path: selected.path,
					category_id: selected.id,
					hasChildren: selected.children.length > 0,
				};
				genericState.categories = {
					...genericState.categories,
					breadcrumbs: [
						...genericState.categories.breadcrumbs,
						newBreadcrumb,
					],
					selectedItems: selected,
				};
			}

			genericState.categories.lastSelected = payload;
		},
		updateSelectedPost: (genericState, { payload }) => {
			genericState.blog.selectedPost = payload;
		},
		updateSelectedVehicleCategory: (
			genericState,
			{ payload },
		) => {
			if (
				typeof payload === 'string' &&
				genericState.vehicles.parentCategories
			) {
				const category =
					genericState.vehicles.parentCategories.find(
						(categ) => categ.id === parseInt(payload),
					);
				if (category) {
					genericState.vehicles.selectedCategory = category;
				}
			} else {
				genericState.vehicles.selectedCategory = payload;
			}
		},
		updateSelectedVehicleId: (
			genericState,
			{ payload },
		) => {
			if (genericState.vehicles?.details) {
				genericState.vehicles = {
					...genericState.vehicles,
					details: {
						...genericState.vehicles.details,
						id: payload,
					},
				};
			}
		},
		resetVehicleFilters: (state) => {
			state.vehicles.filtersApplied.years = [];
			state.vehicles.sortApplied =
				initialState.vehicles.sortApplied;
		},
		toggleVehicleSale: (state) => {
			if (state.vehicles.filtersApplied.active_discount) {
				state.vehicles.filtersApplied.active_discount =
					undefined;
			} else {
				state.vehicles.filtersApplied.active_discount =
					true;
			}
		},
		toggleYear: (state, action) => {
			const {
				yearToFetch,
				onlyReceivedYears = false,
			}: {
				yearToFetch: '2023' | '2024';
				onlyReceivedYears: boolean;
			} = action.payload;

			if (onlyReceivedYears) {
				state.vehicles.filtersApplied.years = [yearToFetch];
			} else {
				const years = state.vehicles.filtersApplied.years;
				if (years.includes(yearToFetch))
					state.vehicles.filtersApplied.years =
						years.filter((year) => year !== yearToFetch);
				else
					state.vehicles.filtersApplied.years = [
						...state.vehicles.filtersApplied.years,
						yearToFetch,
					];
			}
		},
	},
	extraReducers(builder) {
		builder
			.addCase(subscribe.pending, (checkoutState) => {
				checkoutState.subscribe = 'loading';
			})
			.addCase(subscribe.fulfilled, (checkoutState) => {
				checkoutState.subscribe = 'fulfilled';
			})
			.addCase(subscribe.rejected, (checkoutState) => {
				checkoutState.subscribe = 'rejected';
			})
			.addCase(newsletter_unsubscribe.pending, (state) => {
				state.unsubscribe = 'loading';
			})
			.addCase(
				newsletter_unsubscribe.fulfilled,
				(state) => {
					state.unsubscribe = 'fulfilled';
				},
			)
			.addCase(newsletter_unsubscribe.rejected, (state) => {
				state.unsubscribe = 'rejected';
			})

			.addCase(
				fetchCategories.fulfilled,
				(state, action) => {
					state.categories.status = 'fulfilled';
					const categories = action.payload.data;
					if (categories && categories.length > 0) {
						const parentCategories = categories.filter(
							(category) => category.parent_id === null,
						);
						state.categories.items = parentCategories;
					} else {
						state.categories.items = [];
					}
				},
			)
			.addCase(fetchCategories.rejected, (state) => {
				state.categories.status = 'rejected';
				state.categories.items = [];
			})
			.addCase(
				fetchVehicleCategories.fulfilled,
				(state, action) => {
					const vehicleCategories = action.payload.data;
					state.vehicles.isBeingFetched = false;
					state.vehicles.status = 'fulfilled';
					// todo: parentCategories should be categories!

					// state.vehicles.categories = vehicleCategories;
					state.vehicles.parentCategories =
						vehicleCategories;
					// vehicleCategories.filter(
					// 	(category) => category.parent_id === null,
					// );
				},
			)
			.addCase(fetchVehicleCategories.pending, (state) => {
				state.vehicles.isBeingFetched = true;
			})
			.addCase(fetchVehicleCategories.rejected, (state) => {
				state.vehicles.isBeingFetched = false;
				state.categories.status = 'rejected';
				state.vehicles.categories = [];
			})
			.addCase(getPromoMessages.rejected, (state) => {
				state.promoMessages.status = 'rejected';
				state.promoMessages.items = [];
			})
			.addCase(
				getPromoMessages.fulfilled,
				(state, action) => {
					state.promoMessages.status = 'fulfilled';
					const promoData = action.payload?.data;

					if (Array.isArray(promoData)) {
						state.promoMessages.items = promoData.filter(
							(promoItem) => promoItem?.enabled === true, // Use strict comparison
						);
					} else {
						state.promoMessages.items = [];
					}
				},
			)

			.addCase(fetchVehicles.pending, (state, action) => {
				state.vehicles = {
					...state.vehicles,
					isBeingFetched: true,
					selectedVehicle: undefined,
				};
			})
			.addCase(fetchVehicles.fulfilled, (state, action) => {
				state.vehicles = {
					...state.vehicles,
					isBeingFetched: false,
					details: {
						...state.vehicles.details,
						items: [...action.payload.data],
					},
					pagination: action.payload.meta,
				};
			})
			.addCase(fetchVehicles.rejected, (state) => {
				state.vehicles = {
					...state.vehicles,
					isBeingFetched: false,
					details: {
						...state.vehicles.details,
						items: [],
					},
					pagination: undefined,
				};
			})
			.addCase(fetchVehicle.rejected, (state) => {
				state.vehicles = {
					...state.vehicles,
					isQuoteRequestFormShown: false,
					selectedVehicle: undefined,
					isBeingFetched: false,
					details: { items: [] },
				};
			})
			.addCase(fetchVehicle.pending, (state) => {
				state.vehicles = {
					...state.vehicles,
					isQuoteRequestFormShown: false,
					selectedVehicle: null,
					isBeingFetched: true,
				};
			})
			.addCase(fetchVehicle.fulfilled, (state, action) => {
				state.vehicles = {
					...state.vehicles,
					selectedVehicle: action.payload.data,
					isBeingFetched: false,
				};
			})
			.addCase(
				fetchVehiclesForCategory.rejected,
				(state) => {
					state.vehicles = {
						...state.vehicles,
						isQuoteRequestFormShown: false,
						isBeingFetched: false,
					};
				},
			)
			.addCase(
				fetchVehiclesForCategory.pending,
				(state) => {
					state.vehicles = {
						...state.vehicles,
						isQuoteRequestFormShown: false,
						isBeingFetched: true,
					};
				},
			)
			.addCase(
				fetchVehiclesForCategory.fulfilled,
				(state, action) => {
					state.vehicles = {
						...state.vehicles,
						// added this to address below todo,
						// but left those in too for now
						displayable: action.payload.data,
						// todo: refactor this to be the same setup as for product
						details: {
							id: action.payload.categoryId,
							items: action.payload.data,
						},
						isBeingFetched: false,
						pagination: action.payload.meta,
					};
				},
			)
			.addCase(vehicleSearch.rejected, (state) => {
				// ...state.vehicles,
				state.vehicles.isBeingFetched = false;
			})
			.addCase(vehicleSearch.pending, (state) => {
				// ...state.vehicles,
				state.vehicles.isBeingFetched = true;
			})
			.addCase(
				vehicleSearch.fulfilled,
				(state, { payload }) => {
					state.vehicles = {
						...state.vehicles,
						isBeingFetched: false,
						displayable: payload.data,
						pagination: payload.meta,
					};
				},
			)
			.addCase(fetchFilteredVehicles.rejected, (state) => {
				state.vehicles.isBeingFetched = false;
			})
			.addCase(fetchFilteredVehicles.pending, (state) => {
				state.vehicles.isBeingFetched = true;
			})
			.addCase(
				fetchFilteredVehicles.fulfilled,
				(state, { payload }) => {
					state.vehicles = {
						...state.vehicles,
						isBeingFetched: false,
						displayable: payload.data,
						pagination: payload.meta,
					};
				},
			)
			.addCase(fetchSettings.rejected, (state) => {
				state.settings = {
					isBeingFetched: false,
					details: undefined,
				};
			})
			.addCase(fetchSettings.pending, (state) => {
				state.settings = {
					...state.settings,
					isBeingFetched: true,
				};
			})
			.addCase(fetchSettings.fulfilled, (state, action) => {
				state.settings = {
					details: action.payload.data[0],
					isBeingFetched: false,
				};
			})
			.addCase(getBlogPosts.rejected, (state) => {
				state.blog = {
					...state.blog,
					isBeingFetched: false,
					pagination: undefined,
				};
			})
			.addCase(getBlogPosts.pending, (state) => {
				state.blog = {
					...state.blog,
					isBeingFetched: true,
				};
			})
			.addCase(getBlogPosts.fulfilled, (state, action) => {
				state.blog = {
					...state.blog,
					posts: action.payload.data,
					pagination: action.payload.meta,
					isBeingFetched: false,
				};
			})
			.addCase(getSpecificBlogPost.rejected, (state) => {
				state.blog = {
					...state.blog,
					isBeingFetched: false,
					pagination: undefined,
				};
			})
			.addCase(getSpecificBlogPost.pending, (state) => {
				state.blog = {
					...state.blog,
					isBeingFetched: true,
				};
			})
			.addCase(
				getSpecificBlogPost.fulfilled,
				(state, action) => {
					state.blog = {
						...state.blog,
						selectedPost: action.payload.data,
						isBeingFetched: false,
					};
				},
			)
			.addCase(searchBlog.rejected, (state) => {
				state.blog = {
					...state.blog,
					isBeingFetched: false,
					blogsBasedOnSearch: undefined,
					pagination: undefined,
				};
			})
			.addCase(searchBlog.pending, (state) => {
				state.blog = {
					...state.blog,
					isBeingFetched: true,
				};
			})
			.addCase(searchBlog.fulfilled, (state, action) => {
				state.blog = {
					...state.blog,
					blogsBasedOnSearch: action.payload.data,
					isBeingFetched: false,
				};
			});
	},
});

export const gatherCategoryIds = (state: RootState) => {
	const { lastSelected, breadcrumbs } =
		state.generic.categories;
	let categoryIds = breadcrumbs.map(
		(obj) => obj.category_id,
	);
	return lastSelected?.id
		? [...categoryIds, lastSelected.id]
		: categoryIds;
};

const popoverStateSelector = (
	state: RootState,
	popoverType: 'CART' | 'ACCOUNT' | 'LOCALE' | 'SEARCH',
) => {
	if (popoverType === 'CART') {
		return state.generic.cartPopover.isOpen;
	} else if (popoverType === 'ACCOUNT') {
		return state.generic.accountPopover.isOpen;
	} else if (popoverType === 'LOCALE') {
		return state.generic.localePopover.isOpen;
	} else if (popoverType === 'SEARCH') {
		return state.generic.searchPopover.isOpen;
	}
	return;
};

export const selectPopoverState = createSelector(
	[popoverStateSelector],
	(isOpen) => isOpen,
);

const contactFormSelector = (state: RootState) =>
	state.generic.contactForm;
//todo: move the product related selectors etc to productSlice
export const getContactFormResponse = createSelector(
	[contactFormSelector],
	(contactForm) => contactForm,
);

const categoryForProductSelector = (
	state: RootState,
	category_id?: number,
) => {
	if (category_id === undefined) return undefined;
	const { items: categories } = state.generic.categories;
	return (
		categories.find(
			(category) => category.id === category_id,
		) ?? undefined
	);
};
export const selectCategoryForProduct = createSelector(
	[categoryForProductSelector],
	(category) => category,
);
export const getBaseUrl = (state: RootState) =>
	state.generic.baseUrl;

const loadingStatusSelector = (state: RootState) => {
	//siasztokkkkkk
	return (
		state.generic.categories.status !== 'fulfilled' ||
		(!window.location.pathname.includes('shop') &&
			!state.products.displayable &&
			state.products.status !== 'fulfilled') ||
		state.generic.isLoadingScreenShown
	);
};

export const selectIsLoading = createSelector(
	[loadingStatusSelector],
	(isLoading) => isLoading,
);

export const getCategories = (state: RootState) => {
	const { categories } = state.generic;
	if (categories?.items && categories.items.length > 0) {
		return categories.items;
	} else return [];
};

export const getNCategories = (
	state: RootState,
	amount: number,
) => {
	const { items: categories } = state.generic.categories;
	const amountToFetch = Math.min(categories.length, amount);
	return categories?.slice(0, amountToFetch) ?? [];
};
export const {
	updateLocalePopover,
	updateCartPopover,
	updateAccountPopover,
	updateSearchPopover,
	updateBaseUrl,
	updateDialogDetails,
	updateVehicleReuqestFormVisibility,
	updateVehicleForQuote,
	updateBreadcrumbs,
	updateSelectedCategories,
	toggleLoadingScreen,
	updateSelectedCategoriesViaUrl,
	resetForFilter,
	resetBreadcrumbs,
	updateSelectedPost,
	updateSelectedVehicleId,
	updateSelectedVehicleCategory,
	updateSearchType,
	toggleYear,
	toggleVehicleSale,
	resetVehicleFilters,
	updatePromoPopover,
	applyVehicleSort,
	setCookieConsentDialogVisibility,
} = genericSlice.actions;

export const genericReducer = genericSlice.reducer;
