import {
	Grid,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ProductSection } from './components/ProductSection';
import { VehicleSection } from './components/VehicleSection';
import {
	Filter,
	VehicleCategories,
	VehicleFilters,
} from './components/Filter'; // Importing the Filter for products
import {
	RootState,
	store,
	updateSearchType,
	fetchVehiclesForCategory,
	updatePageNumber,
	updateSelectedVehicleCategory,
	handleViewChange,
} from '../../redux';
import styles from './shop.module.scss';
import { parseFilterParametersFromUrl } from './helpers';
import { BreadcrumbSection } from './components/BreadcrumbSection';
import { FilterDialog } from './components/FilterDialog';
import { SortSection } from './components/SortSection';
import { Helmet } from 'react-helmet';

export const Shop = ({
	isVehicles = false,
}: {
	isVehicles?: boolean;
}) => {
	const [isFilterDialogOpen, setIsFilterDialogOpen] =
		useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('md'),
	);
	const isBelowLargeScreen = useMediaQuery(
		theme.breakpoints.down('lg'),
	);
	const isGridView = useSelector(
		(state: RootState) => state.shop.isGridView,
	);

	const location = useLocation();

	// Handle filter dialog toggle
	const toggleFilterDialog = () =>
		setIsFilterDialogOpen((prev) => !prev);

	useEffect(() => {
		store.dispatch(
			updateSearchType(isVehicles ? 'vehicle' : 'item'),
		);
	}, [isVehicles]);

	useEffect(() => {
		if (isVehicles) {
			const queryParams = new URLSearchParams(
				location.search,
			);
			const categoryId = queryParams.get('category');
			const page = queryParams.get('page');
			if (page) {
				store.dispatch(updatePageNumber(parseInt(page)));
			}
			if (categoryId) {
				store.dispatch(
					updateSelectedVehicleCategory(categoryId),
				);
				store.dispatch(
					fetchVehiclesForCategory({ categoryId, page }),
				);
			}
		}
	}, [location, isVehicles]);

	// Auto-change view mode if screen size changes
	useEffect(() => {
		if (isBelowLargeScreen && !isGridView) {
			store.dispatch(handleViewChange('grid'));
		}
	}, [isBelowLargeScreen, isGridView]);

	// Handle location state and filter parameters for products
	useEffect(() => {
		if (!isVehicles) {
			window.history.replaceState({}, document.title);
			const previousPath = location.state?.from?.pathname;
			parseFilterParametersFromUrl(previousPath);
		}
	}, [isVehicles, location]);
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	return (
		<>
			<Helmet>
				{isVehicles ? (
					currentLocale === 'hu' ? (
						<title>Járművek</title>
					) : (
						<title>Vehicles</title>
					)
				) : currentLocale === 'hu' ? (
					<title>Termékek</title>
				) : (
					<title>Products</title>
				)}
			</Helmet>
			<Grid
				container
				maxWidth="lg"
				sx={{
					margin: '0 auto',
					justifyContent: 'space-between',
				}}
			>
				{/* Breadcrumb Section */}
				{!isVehicles ? <BreadcrumbSection /> : null}

				{/* Filter Dialog for small screens */}
				{isSmallScreen && (
					<FilterDialog
						isFilterDialogOpen={isFilterDialogOpen}
						toggleFilterDialog={toggleFilterDialog}
						setIsLoading={setIsLoading}
						isVehicles={isVehicles}
					/>
				)}

				{/* Left sidebar for filters (visible on larger screens) */}
				{!isSmallScreen && (
					<Grid
						item
						xs={12}
						md={isVehicles ? 3 : 4}
						lg={3}
						className={styles.filterContainer}
						sx={{
							minHeight: '30vh',
							height: 'max-content',
							alignItems: 'flex-start',
							display: 'flex',
							textAlign: 'center',
							flexDirection: 'column',
							position: isSmallScreen
								? 'relative'
								: 'sticky',
							top: 0,
							boxSizing: 'border-box',
							padding: 1,
							overflowY: 'auto',
						}}
					>
						{isVehicles ? (
							<Grid container flexDirection="column">
								<VehicleCategories />
								<VehicleFilters
									setIsLoading={setIsLoading}
								/>
							</Grid>
						) : (
							<Filter setIsLoading={setIsLoading} />
						)}
					</Grid>
				)}

				{/* Main content area (products or vehicles) */}
				<Grid
					item
					xs={12}
					md={isVehicles ? 9 : 8}
					lg={9}
					className={styles.gridContainer}
					sx={{ paddingLeft: { lg: 2 } }}
				>
					{/* Sorting and View Options */}
					<SortSection
						isBelowLargeScreen={isBelowLargeScreen}
						toggleFilterDialog={toggleFilterDialog}
						isVehicles={isVehicles}
						setIsLoading={setIsLoading}
					/>

					{/* Product or Vehicle Section */}
					{isVehicles ? (
						<VehicleSection isLoading={isLoading} />
					) : (
						<ProductSection isLoading={isLoading} />
					)}
				</Grid>
			</Grid>
		</>
	);
};
