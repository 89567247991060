import React, { useEffect, useState } from 'react';
import {
	Snackbar,
	Box,
	Typography,
	Button,
	Grid,
	Checkbox,
	FormControlLabel,
	IconButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'js-cookie';
import { useTranslation } from '../../helpers';
import { initializeSentry } from '../../helpers/sentry';
import {
	RootState,
	setCookieConsentDialogVisibility,
	store,
} from '../../redux';
import { useSelector } from 'react-redux';

interface ConsentPreferences {
	essential: boolean;
	analytics: boolean;
	marketing: boolean;
}

const defaultPreferences: ConsentPreferences = {
	essential: true,
	analytics: true,
	marketing: true,
};
const removeGoogleAnalytics = () => {
	const gaScript = document.querySelector(
		'script[src*="googletagmanager.com/gtag/js"]',
	);
	if (gaScript) {
		gaScript.remove(); // Remove the script tag
	}
	// disable analytics script execution
	// @ts-ignore
	if (typeof window !== 'undefined' && window.gtag) {
		window['ga-disable-G-52GGM1GK6Q'] = true; // Replace with your GA Tracking ID
		// @ts-ignore
		window.gtag = function () {}; // Override gtag function with a no-op
	}
};

const loadGoogleAnalytics = () => {
	if (
		!document.querySelector(
			'script[src*="googletagmanager.com/gtag/js"]',
		)
	) {
		const script = document.createElement('script');
		script.async = true;
		script.src =
			'https://www.googletagmanager.com/gtag/js?id=G-52GGM1GK6Q'; // Replace with your GA ID
		document.head.appendChild(script);

		script.onload = () => {
			// @ts-ignore
			window.dataLayer = window.dataLayer || [];
			const gtag = (...args: any[]) => {
				// @ts-ignore
				window.dataLayer.push(args);
			};
			// @ts-ignore
			window.gtag = gtag;
			gtag('js', new Date());
			gtag('config', 'G-52GGM1GK6Q'); // Replace with your GA ID
		};
	}
};
export const applyConsent = (prefs: ConsentPreferences) => {
	if (!prefs.analytics) {
		removeGoogleAnalytics();
		Cookies.remove('_ga');
		Cookies.remove('_gid');
		Cookies.remove('_ga_52GGM1GK6Q');
		initializeSentry(false);
	} else {
		loadGoogleAnalytics();
		initializeSentry(true);
	}

	if (!prefs.marketing) {
		// if any, block marketing cookies here
	}
};
export const CookieConsentDialog = () => {
	const open = useSelector(
		(state: RootState) =>
			state.generic.showCookieConsentDialog,
	);
	const [preferences, setPreferences] =
		useState<ConsentPreferences>(defaultPreferences);

	const translations = useTranslation();

	useEffect(() => {
		const savedConsent =
			localStorage.getItem('cookieConsent');
		if (savedConsent) {
			const parsedPreferences = JSON.parse(savedConsent);
			setPreferences(parsedPreferences);
			applyConsent(parsedPreferences); // Apply preferences immediately
		} else {
			store.dispatch(
				setCookieConsentDialogVisibility(true),
			);
		}
	}, []);
	const handleSavePreferences = () => {
		localStorage.setItem(
			'cookieConsent',
			JSON.stringify(preferences),
		);
		store.dispatch(setCookieConsentDialogVisibility(false));

		applyConsent(preferences);
	};

	const handleChange = (
		category: keyof ConsentPreferences,
	) => {
		setPreferences((prev) => ({
			...prev,
			[category]: !prev[category],
		}));
	};

	return (
		<>
			{open && (
				<div
					style={{
						position: 'fixed',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						backgroundColor: 'rgba(0, 0, 0, 0.5)',
						zIndex: 999,
					}}
				/>
			)}
			<Snackbar
				open={open}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				sx={{
					bottom: 16,
					maxWidth: '90%',
					margin: '0 auto',
					height: 'max-content',
				}}
			>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="space-between"
					paddingTop={4}
					paddingBottom={2}
					px={2}
					sx={{
						backgroundColor: 'background.default',
						boxShadow: 3,
						borderRadius: 1,
						width: '100%',
						position: 'relative',
					}}
				>
					<Typography
						variant="body2"
						sx={{ flex: 1, pr: 2 }}
					>
						{translations.text.cookie_notice}{' '}
						<Link
							to="/cookie-policy"
							style={{
								color: 'inherit',
								textDecoration: 'underline',
							}}
						>
							{translations.navigation.privacy_policy}
						</Link>
						.
					</Typography>
					<Grid
						container
						marginTop={2}
						justifyContent="flex-start"
					>
						<FormControlLabel
							control={<Checkbox checked disabled />}
							label={translations.checkbox.essential}
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={preferences.analytics}
									onChange={() => handleChange('analytics')}
								/>
							}
							label={translations.checkbox.analytics}
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={preferences.marketing}
									onChange={() => handleChange('marketing')}
								/>
							}
							label={translations.checkbox.marketing}
						/>
					</Grid>
					<Grid container spacing={2} marginTop={2}>
						<Grid item xs={6}>
							<Button
								variant="brp-black"
								size="medium"
								fullWidth
								onClick={() => {
									setPreferences(defaultPreferences);
									handleSavePreferences();
								}}
							>
								{translations.buttons.decline_all}
							</Button>
						</Grid>
						<Grid item xs={6}>
							<Button
								variant="brp-yellow"
								size="small"
								fullWidth
								onClick={handleSavePreferences}
							>
								{translations.buttons.save}
							</Button>
						</Grid>
					</Grid>
					<IconButton
						color="inherit"
						size="small"
						onClick={() =>
							store.dispatch(
								setCookieConsentDialogVisibility(false),
							)
						}
						sx={{
							position: 'absolute',
							top: 0,
							left: 0,
						}}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
				</Box>
			</Snackbar>
		</>
	);
};
