import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
	BrowserRouter,
	Routes,
	Route,
} from 'react-router-dom';
import { store } from './redux';
import App from './App';
import { ThemeProvider } from './themes/index';
import './styles/general.scss';
import { GlobalStyles } from '@mui/material';
import { ScrollToTop } from './helpers';
import { GenericDialog } from './components';
import {
	applyConsent,
	CookieConsentDialog,
} from './components/dialogs/CookieConsentDialog';

const savedConsent = JSON.parse(
	localStorage.getItem('cookieConsent') || '{}',
);
applyConsent(savedConsent);

const container = document.getElementById(
	'root',
) as HTMLElement;

const inputGlobalStyles = <GlobalStyles styles={{}} />;

const root = createRoot(container);
root.render(
	<Provider store={store}>
		<ThemeProvider>
			{inputGlobalStyles}
			<BrowserRouter>
				<GenericDialog />
				<ScrollToTop />
				<Routes>
					<Route path="/*" element={<App />} />
				</Routes>
			</BrowserRouter>
		</ThemeProvider>
	</Provider>,
);
