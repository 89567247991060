import * as Sentry from '@sentry/react';

const SENTRY_DSN =
	'https://6d2e28f35b024cc479fa59d1b61949a6@o4508043621105664.ingest.de.sentry.io/4508043635654736';

export const initializeSentry = (enable: boolean) => {
	if (
		process.env.REACT_APP_ENV === 'production' &&
		enable
	) {
		if (!Sentry.isInitialized) {
			Sentry.init({
				dsn: SENTRY_DSN,
				integrations: [
					Sentry.browserTracingIntegration(),
					Sentry.replayIntegration(),
				],
				tracesSampleRate: 0.4,
				replaysSessionSampleRate: 0.1,
				replaysOnErrorSampleRate: 1.0,
			});
		}
	} else {
		if (!Sentry.isInitialized) {
			Sentry.close();
		}
	}
};
